import { mapGetters } from "vuex";
import header from "@/assets/table/receivable/receivableSalesHeaders";
import filterNormal from "@/assets/image/svg/filter.svg";
import filterActive from "@/assets/image/svg/filter_active.svg";
import CommonTooltip from "@/components/atoms/VTooltip/CommonTooltip.vue";

import { receivableFilters } from "@/api/receivable";
import commitName from "@/components/pages/common/ApiActionName.js";

export default {
  components: {
    filterNormal,
    filterActive,
    CommonTooltip,
  },
  computed: {
    ...mapGetters({
      pageParams: "getSalesRecivableParam",
      pageList: "getSalesRecivableList",
      totalCount: "getRecivableTotalCount",
      monthList: "getMonthList",
      vatType: "getSalesRecivableType",
      sort: "getVatSalesSort",

      pageIndex: "getPageIndex",
    }),
    searchData() {
      return this.setSearchData("search", this.names.searchCommitName);
    },
    totalData() {
      return this.setSearchData("search", this.names.totalCommitName);
    },
    infiniteScrollData() {
      return this.setSearchData("infinite", this.names.infiniteCommitName);
    },
    isPluseParamBtn() {
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );
      return !!this.isKeyword && -1 < paramIndex;
    },
    isApplyBtn() {
      let isApply = this.isKeyword
        ? this.isPluseParam
          ? !this.isPluseParam
          : !this.keywordFilters.filter((x) => x.checked).length
        : !this.filters.filter((x) => x.checked).length;
      return isApply;
    },
  },
  data() {
    return {
      header,
      observer: null,
      names: commitName["salesVat"],

      filterField: "", // 현재 선택한 filter field
      isFilter: false,
      allChecked: false, // 모두 선택 checkbox
      keywordAllChecked: false, // keywod 검색 filter 모두 선택 check box
      filters: [], // 현재 filter 데이터
      params: [], // checked 가 true 데이터
      infinitieSearchField: "", //무한 스크롤 field
      // sort: 1, // 정렬 선택 1: 오림차순, -1 :내림 차순
      isKeyword: false, // filter keyword 입력 여부
      findKeyword: "", // filter 검색을 위한 keyword
      keywordFilters: [], // keyword 가 포함된 filter
      isPluseParam: false,

      deposit_amount: "",
      vat_amount: "",
      balance: "",
      type: "",
      expiration: "",
      onRow: "",
      editRow: [],
      editItem: {},
      deposit_date: "",
      currency_sign: "₩",
      memo: "",
      menu_deposit_details: false,
      menu_date_expiration: false,
      menu_date_deposit: false,
      edit_menu_date_expiration: [],
      edit_menu_date_deposit: [],
      depositDetailsRow: "",
      depositHeder: [
        {
          value: "deposit_date",
          text: "입금날짜",
          align: "center",
          width: "10%",
          class: "tableHeaderBody",
        },
        {
          value: "deposit_amount",
          text: "입금액",
          align: "center",
          width: "15%",
          class: "tableHeaderBody",
        },
        {
          value: "deposit_type",
          text: "입금형태",
          align: "center",
          width: "10%",
          class: "tableHeaderBody",
        },
        {
          value: "expiration",
          text: "만기일",
          align: "center",
          width: "10%",
          class: "tableHeaderBody",
        },

        {
          value: "memo",
          text: "비고",
          align: "center",
          width: "15%",
          class: "tableHeaderRigth",
        },
      ],
      keydownListener: null,
    };
  },

  methods: {
    /**
     * api 호출
     */
    // 검색 할 값 생성
    setSearchData(type, name) {
      if (!this.pageParams.length) this.infinitieSearchField = "";
      const data = {
        param: {
          multi: JSON.stringify(this.pageParams),
          field:
            type === "infinite"
              ? this.infinitieSearchField
              : this.setFilterField(),
          sort: this.sort,
          kind: this.names.kind,
          type: this.vatType,
        },
        commitName: name,
        isInfinite: type !== "infinite",
      };
      if (type === "infinite") data.param.skip = this.pageList.length;

      return data;
    },

    // 검색 api 호출
    search() {
      this.callTotalCountAPI();
      this.callSearchAPI();
    },

    // 검색시 sort field 생성
    setFilterField() {
      let field = "";
      if (this.filterField) {
        field = this.filterField;
      }
      if (this.pageParams.length && !this.filterField) {
        field = this.pageParams[this.pageParams.length - 1].field;
      }
      return field;
    },

    // 내부 필터 데이터 호출 api
    async setFilters() {
      const head = header.find((haed) => haed.value === this.filterField);
      try {
        const result = await receivableFilters({
          company: this.$store.state.auth_company,
          params: {
            field: this.filterField,
            multi: this.setFilterParam(),
            kind: this.names.kind,
            type: this.vatType,
            sort: head.sort,
          },
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        const params = this.copyData(
          this.pageParams.find((x) => x.field === this.filterField),
        );

        if (!params) {
          this.filters = result.data.length ? result.data : [];
          this.setParams();
        } else {
          this.params = params.params;
          this.filters = result.data.map((x) => {
            const param = this.params.find((name) => name === x.name);
            return {
              name: x.name,
              checked: param !== undefined,
            };
          });
          this.allChecked = this.params.length === this.filters.length;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // filter api 호출시 현재 필드 값을 뺴고 multi 생성
    setFilterParam() {
      let params = [];
      const filterIndex = this.pageParams.findIndex(
        (x) => x.field === this.filterField,
      );
      if (-1 < filterIndex) {
        for (let i = 0; i < filterIndex; i++) {
          params.push(this.pageParams[i]);
        }
      } else params = this.pageParams;

      return JSON.stringify(params);
    },

    // 오더 data 검색 api
    async callSearchAPI() {
      this.$store.dispatch(this.names.searchApiName, this.searchData);
      this.infinitieSearchField = this.setFilterField();
    },

    //무한스크롤 API
    async infiniteScroll() {
      this.$store.dispatch(this.names.searchApiName, this.infiniteScrollData);
    },

    //검색된 오더 total count api
    async callTotalCountAPI() {
      this.$store.dispatch(this.names.totalApiName, this.totalData);
    },

    //head 에 값에 따로 sort 값 전달
    headSort(field) {
      const head = header.find((haed) => haed.value === field);
      return head && head.sort ? head.sort : 1;
    },

    /**
     * 필터 이벤트
     */

    // 필터 비콘 클릭 이벤트
    filterBeaconClick(head) {
      this.filterField !== head ? this.filterSet(head) : this.initFilter();
      this.findKeyword = "";
      this.params = [];
    },

    //필터 초기화 기능
    initFilter() {
      this.filterField = "";
      this.findKeyword = "";
      this.isFilter = false;
      this.allChecked = false;
      this.isKeyword = false;
      this.filters = [];
      this.keywordFilters = [];
    },

    // 필터 set 기능
    filterSet(head) {
      this.filterField = head;
      this.isFilter = true;
      this.filters = [];
      this.setFilters();
    },

    // 필터 활성화시 최초 params set
    setParams() {
      this.params = this.filters.filter((x) => x.checked).map((x) => x.name);
      this.allChecked = this.params.length === this.filters.length;
    },

    allChekedClick() {
      this.allChecked = !this.allChecked;
      this.filters = this.filters.map((x) => {
        x.checked = this.allChecked;
        return x;
      });
      this.params = this.allChecked
        ? this.filters.filter((x) => x.checked).map((x) => x.name)
        : [];
    },

    // checkbox 클릭시 이벤트
    checkBoxClick(i) {
      this.filterChecked(i);
      this.modifyParams(i);
      this.allChecked = this.params.length === this.filters.length;
    },

    // filter checked 변경
    filterChecked(i) {
      const filters = this.copyData(this.filters);
      filters[i].checked = !filters[i].checked;
      this.filters = filters;
    },

    // 현재 활성화된 filter 검색 params set
    modifyParams(i) {
      const filter = this.filters[i];
      if (filter.checked) {
        this.params.push(filter.name);
        this.params = [...new Set(this.params)];
      } else {
        const index = this.params.findIndex((x) => x === filter.name);
        this.params.splice(index, 1);
      }
    },

    // 필터 적용
    applyBtnClick() {
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.filterField),
      );
      // this.sort = this.headSort(this.filterField);
      // this.sort = 1;
      this.commitParams();
      this.search();
      // this.modifyBeacon(true);
      this.initHeader();
      this.initFilter();
    },

    // 오름, 내림 차순
    sortBtnClick(sort) {
      // this.sort = sort;
      this.$store.commit(this.names.sortCommitName, sort);
      this.search();
      this.initFilter();
    },

    // beacon checked 변경
    modifyBeacon(checked) {
      const index = header.findIndex((head) => head.value === this.filterField);
      header[index].checked = checked;
    },

    // 검색 버튼 클릭시 멀티 params 값 변경
    commitParams() {
      const pageParams = this.copyData(this.pageParams);
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );

      const params = {
        field: this.filterField,
        params: this.params,
      };
      if (paramIndex < 0) pageParams.push(params);
      else pageParams[paramIndex] = params;

      this.$store.commit(this.names.paramName, pageParams);
    },

    monthFilter(month, type) {
      const list = this.filters.filter(
        (date) => this.monthParse(date.name, type) === month,
      );

      if (list.length) {
        this.params = list.map((date) => date.name);
        this.applyBtnClick();
      } else this.initParams();
    },

    monthParse(date, type) {
      let parseDate = date ? date : "";

      if (type === "mmdd") parseDate = parseDate.substr(0, 2);

      if (type === "yymm") parseDate = parseDate.substr(3, 5);

      if (type === "yyyymm") parseDate = parseDate.substring(5, 7);

      if (type === "m" && +date < 10) parseDate = `0${parseDate}`;

      return parseDate;
    },

    // 필터 해제
    initParams() {
      this.params = [];
      const orderParams = this.copyData(this.pageParams);
      const index = orderParams.findIndex(
        (param) => param.field === this.filterField,
      );
      orderParams.splice(index, 1);
      this.$store.commit(this.names.paramName, orderParams);
      this.setFilters();
      this.initFilter();
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.setFilterField()),
      );
      this.search();
      this.initHeader();
    },

    /**
     * 필터 검색
     */
    // 입력된 keyword 를 filter List 검색
    inputKeyowrd() {
      this.isPluseParam = false;
      this.isKeyword = !!this.findKeyword;
      this.keywordFilters = this.copyData(
        this.filters.filter((x) =>
          String(x.name).toLowerCase().includes(this.findKeyword.toLowerCase()),
        ),
      );
      this.keywordAllChecked = this.keywordFilters.length
        ? !this.keywordFilters.find((x) => !x.checked)
        : false;
    },

    // 입력된 keyword filter checkbox 클릭
    keywordCheckBoxClick(i) {
      this.keywordFilters[i].checked = !this.keywordFilters[i].checked;
      const index = this.filters.findIndex(
        (x) => x.name === this.keywordFilters[i].name,
      );
      this.checkBoxClick(index);
    },

    // 입력된 keyword filter 전체버튼 클릭
    keywordAllcheckedClick() {
      const params = this.params;
      this.keywordAllChecked = !this.keywordAllChecked;
      this.keywordFilters = this.keywordFilters.map((x) => {
        x.checked = this.keywordAllChecked;
        return x;
      });
      this.keywordFilters.forEach((filter) => {
        if (this.keywordAllChecked) {
          params.push(filter.name);
        } else {
          const index = this.params.findIndex((x) => x === filter.name);
          params.splice(index, 1);
        }
      });
      this.params = [...new Set(params)];
    },

    keywordApplyBtnClick() {
      if (!this.isPluseParam) {
        const keywordParam = this.keywordFilters.filter((x) => x.checked);
        this.params = keywordParam.length
          ? keywordParam.map((x) => x.name)
          : this.params;
      }
      this.applyBtnClick();
    },

    // 숫자 필터 사용시 검색 함수
    numFiltering(field, pageParams) {
      this.filterField = field;
      this.$store.commit(this.names.paramName, pageParams);
      this.search();
      this.initHeader();
      this.initFilter();
    },

    // 숫자 필터 다이어로그 open
    numDialogOpen(type) {
      this.$store.dispatch("NUM_DIALOG_OPEN", {
        field: this.filterField,
        filters: this.copyData(this.filters),
        params: this.copyData(this.pageParams),
        type: type,
        numFiltering: this.numFiltering,
      });
    },

    async checkScroll() {
      const scroll = this.$refs.endPoint;
      this.observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && this.pageList.length < this.totalCount) {
            this.infiniteScroll();
          }
        },
        {
          threshold: 0.5,
        },
      );
      this.observer.observe(scroll);
    },

    initHeader() {
      header.map((head) => {
        head.checked = !!this.pageParams.find(
          (param) => param.field === head.value,
        );
        return head;
      });
    },

    pageMove() {
      this.pageParams.splice(0, 1);
      this.$store.commit(this.names.paramName, this.pageParams);
      this.callTotalCountAPI();

      if (this.totalCount) {
        this.callSearchAPI();
        this.initHeader();
      } else {
        this.$store.commit(this.names.paramName, []);
        this.search();
      }
    },

    // 외상 매입금 입력 기능
    clickRow(i, item) {
      const receivable = JSON.parse(JSON.stringify(item));
      this.deposit_amount = "";
      this.vat_amount = "";
      this.type = "";
      this.memo = "";
      this.expiration = "";
      this.deposit_date = "";
      this.balance =
        receivable.balance && receivable.balance !== "(비어있음)"
          ? this.roundsData(receivable.balance)
          : receivable.balance === 0
          ? 0
          : receivable.vat_amount;
      this.onRow = i;
      this.currency_sign = item.orginal_amount
        ? "₩"
        : item.currency_sign
        ? item.currency_sign
        : "₩";
    },

    clearRow() {
      this.deposit_amount = "";
      this.vat_amount = "";
      this.type = "";
      this.balance = "";
      this.expiration = "";
      this.onRow = "";
      this.memo = "";
      this.deposit_date = "";
    },
    modifyRow(i, item, editIndex) {
      this.editRow = [i, editIndex];
      this.editItem = { ...item };
      if (this.editItem.deposit_date)
        this.editItem.deposit_date = this.dateFormat(item.deposit_date);
      if (this.editItem.expiration)
        this.editItem.expiration = this.dateFormat(item.expiration);
    },

    modifyClearRow() {
      this.editRow = [];
    },
    // async deleteReceivableAmount(item) {
    //   if (confirm("삭제 하시겠습니까?")) {
    //     this.$store.commit("setAPILoading", true);
    //     await deleteReceivableAmountbyId(
    //       this.$store.state.auth_company,
    //       item._id,
    //     );
    //     this.$store.dispatch("PARSE_ROUTE_API_DATA_CALL");

    //     this.$store.commit("setAPILoading", false);
    //   }
    // },
    balanceCaculate(item) {
      this.balance = parseFloat(
        (+item.balance - +this.deposit_amount).toFixed(2),
      );
    },
    editBalanceCaculate(item, deposit) {
      let depostiAmount = +item.balance + +deposit.deposit_amount;
      depostiAmount -= +this.editItem.deposit_amount;
      return;
    },

    onDepositDetails(data, i) {
      this.depositDetailsRow = !this.menu_deposit_details ? i : "";
      this.depositDetailsList = !this.menu_deposit_details ? data : "";
      this.menu_deposit_details = !this.menu_deposit_details;
    },
    validateSaveReceivable(type) {
      if (type === "delete") return true;
      const newValidate = () => {
        if (
          this.deposit_amount === undefined ||
          this.deposit_amount === 0 ||
          this.deposit_amount === ""
        ) {
          this.$store.commit("setSnackBar", "입금액을 입력해주세요");
          return false;
        }
        if (!this.type) {
          this.$store.commit("setSnackBar", "입금 형태 를 선택해 주세요");
          return false;
        }
        if (!this.deposit_date) {
          this.$store.commit("setSnackBar", "지급날짜를 선택해 주세요");
          return false;
        }
        return true;
      };
      const editValidate = () => {
        if (
          this.editItem.deposit_amount === undefined ||
          this.editItem.deposit_amount === 0 ||
          this.editItem.deposit_amount === ""
        ) {
          this.$store.commit("setSnackBar", "입금액을 입력해주세요");
          return false;
        }
        if (!this.editItem.type) {
          this.$store.commit("setSnackBar", "입금 형태 를 선택해 주세요");
          return false;
        }
        if (!this.editItem.deposit_date) {
          this.$store.commit("setSnackBar", "지급날짜를 선택해 주세요");
          return false;
        }
        return true;
      };
      const validate = {
        new: newValidate,
        edit: editValidate,
      };

      return validate[type]();
    },
    parseReceivable(type, data) {
      const newParse = (data) => {
        const receivableAmount = {};
        receivableAmount.company = this.$store.state.auth_company;
        receivableAmount.deposit_date = this.deposit_date;
        receivableAmount.deposit_amount = +this.deposit_amount; //입금액
        // receivableAmount.balance = this.roundsData(this.balance); // 잔액
        receivableAmount.type = this.type; // 입금형태
        receivableAmount.receivable = data._id;
        receivableAmount.memo = this.memo;
        receivableAmount.currency_sign = this.currency_sign;
        if (this.expiration) receivableAmount.expiration = this.expiration; //만기일자
        this.$store.commit("setSaveRecivableAmount", receivableAmount);
      };
      const editParse = () => {
        const receivableAmount = { ...this.editItem };

        this.$store.commit("setSaveRecivableAmount", receivableAmount);
      };
      const parse = {
        new: newParse,
        edit: editParse,
      };
      return parse[type](data);
    },
    initFormData(type) {
      type === "new" ? this.clearRow() : this.modifyClearRow();
    },
    async taskSaveReceivable(form, type) {
      const messeage =
        type === "delete" ? "삭제" : type === "new" ? "등록" : "수정";
      if (!this.validateSaveReceivable(type)) return;
      if (confirm(`${messeage} 하시겠습니까?`)) {
        if (type !== "delete") await this.parseReceivable(type, form);
        await this.$store.dispatch("TASK_RECEIVEABLE_SAVE", {
          form:
            type === "delete" ? form : this.$store.state.saveRecivableAmount,
          type,
        });

        this.initFormData(type);
      }
    },

    // 외상 매입금 입력 기능 끝
    typeCheck() {
      if (this.type == 1 || this.type === 4 || this.type === 5)
        this.expiration = "";
    },
  },

  async created() {
    if (this.pageParams.length && this.pageParams[0].field === "pageMove")
      this.pageMove();
    else {
      this.search();
    }
    // this.$store.dispatch("SET_API_LOADING", true);
    // await this.$store.dispatch("GET_VAT_RECEIVABLE", {
    //   vatKind: "sales",
    //   vatType: "common",
    //   sortHeader: this.$store.state.applySortList["salesVat"],
    // });
    // // this.$store.dispatch("HEADER_FILTER_DATA", {
    // //   vatType: "common",
    // //   vatKind: "sales",
    // // });
    // // this.$store.dispatch("ORIGIN_LIST", { vatType: "common" });
    // this.$store.commit("setNumFilterList", this.originalList);
    // this.createdFilterSet();
    // this.$store.dispatch("SET_API_LOADING", false);
  },

  mounted() {
    this.keydownListener = (e) => {
      if (e.keyCode == 27 && this.isFilter) {
        this.initFilter();
      }
    };
    document.addEventListener("keydown", this.keydownListener);
    this.checkScroll();
  },
  destroyed() {
    // this.observer.disconnect();
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.endPoint);
    this.observer.disconnect();
    document.removeEventListener("keydown", this.keydownListener);
    this.keydownEventListener = null;
  },
};
